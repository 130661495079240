import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Button from "../components/Button";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import TextField from "../components/TextField";
import FlashMessage from "../components/FlashMessage";
import { forgotPasswordSubmit, signIn } from "./amplify";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

class ConfirmForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      error: undefined,
      code: "",
    };
  }

  componentDidMount() {
    this.handleUrl();
  }

  handleUrl() {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.has("code")) {
      this.setState({ code: searchParams.get("code") });
    }
  }

  onChange = (ev) => {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  submit = async (ev) => {
    ev.preventDefault();
    const { email } = this.props;
    const { code, password, password2 } = this.state;

    if (!email || !password || !code) {
      return;
    }

    if (password !== password2) {
      this.setState({
        error: "Passwords don't match",
      });
      return;
    }

    this.setState({
      submitting: true,
    });

    try {
      await forgotPasswordSubmit(email, code, password);
      await signIn(email, password);
      window.history.replaceState({}, "", "/");
    } catch (e) {
      this.setState({
        submitting: false,
        error: e.message,
      });
      console.error(e);
    }
  };

  clearFlashMessage = () => this.setState({ message: undefined });

  render() {
    const { code, password, password2 } = this.state;

    if (!this.props.email) {
      return null;
    }

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    return (
      <Page>
        <Header />
        {this.state.error && (
          <FlashMessage
            message={this.state.error}
            isDanger={true}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Confirm email"
            actionIcons={<Button>Change password</Button>}
          >
            <p>
              We sent an email to {this.props.email} with an activation code,
              please enter that code below to verify your email address.
            </p>
            <StyledTextField
              className="code-input"
              id="code"
              hint="Code"
              onChange={this.onChange}
              value={code}
            />
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password"
              hint="New password"
              helperText="Minimum of 8 characters"
              value={password}
              autoComplete="new-password"
            />
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password2"
              hint="Repeat new password"
              value={password2}
              autoComplete="new-password"
            />
          </Card>
        </form>
      </Page>
    );
  }
}

export default ConfirmForgotPasswordForm;
