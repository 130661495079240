import storeSession from "./store_session";

let storage;

try {
  storage = storeSession ? localStorage : sessionStorage;
} catch (_) {
  let storageObject = {};
  storage = class InMemoryStorage {
    // set item with the key
    static setItem(key, value) {
      storageObject[key] = value;
    }
    // get item with the key
    static getItem(key) {
      return storageObject[key];
    }
    // remove item with the key
    static removeItem(key) {
      delete storageObject[key];
    }
    // clear out the storage
    static clear() {
      storageObject = {};
    }
    // If the storage operations are async(i.e AsyncStorage)
    // Then you need to sync those items into the memory in this method
    static sync() {
      return Promise.resolve();
    }
  };
}

export default storage;
