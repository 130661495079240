import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-decoration: underline;
  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;

  cursor: pointer;

  color: ${({ color }) => color || "#24a7e0"}

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  -webkit-appearance: none;

  &[disabled] {
    opacity: 0.8;
  }

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`;

export default class LinkButton extends React.Component {
  render() {
    const { children, ...rest } = this.props;
    return <StyledButton {...rest}>{children}</StyledButton>;
  }
}
