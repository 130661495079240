import React from "react";
import styled from "styled-components";
import classNames from "classnames";

const BASE_URL = "https://status.home-assistant.io";
const COMPONENTS_URL = `${BASE_URL}/api/v2/components.json`;
const CLOUD_COMPONENT_ID = "q8rzyqg3gjw1";

const StyledDiv = styled.div`
  --status-page-component-status-color: #24a7e0;
  color: var(--status-page-component-status-color);

  a {
    color: var(--status-page-component-status-color) !important;
    display: flex;
    gap: 2px;
    align-items: center;
    text-decoration: none;
    margin-left: 8px;
  }

  svg {
    width: 16px;
    fill: var(--status-page-component-status-color);
  }

  .status-page-component-status {
    border-bottom: 1px solid var(--status-page-component-status-color);
  }

  .status-page-component-status:first-letter {
    text-transform: uppercase;
  }

  .status-page-component-status-partial_outage,
  .status-page-component-status-unknown {
    --status-page-component-status-color: #ffa600;
  }

  .status-page-component-status-major_outage {
    --status-page-component-status-color: #ca1515;
  }

  .status-page-component-status-operational {
    --status-page-component-status-color: #24a7e0;
  }

  @media (max-width: 768px) {
    a {
      margin-left: 0;
    }
  }
`;

const statusSvgPath = {
  operational:
    "M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z",
  default:
    "M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z",
};

const statusTransform = {
  unknown: "Unknown service status",
  operational: "Services are operational",
  partial_outage: "Partial service outage",
  major_outage: "Major service outage",
};

export default class StatusPageComponentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.fetchComponents();
    this.state = {
      components: [],
    };
  }

  async fetchComponents() {
    try {
      const response = await fetch(COMPONENTS_URL);
      const data = await response.json();
      this.setState({ components: data?.components || [] });
    } catch (error) {
      console.error("Error fetching status page components", error);
    }
  }

  render() {
    const { components } = this.state;
    const cloudStatus = components?.find(
      (component) => component.id === CLOUD_COMPONENT_ID
    );

    const currentCloudStatus = cloudStatus?.status || "operational";

    return (
      <React.Fragment>
        <StyledDiv>
          <a
            title="Open our status page"
            href={BASE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <svg
              className={classNames(
                `status-page-component-status-${currentCloudStatus}`
              )}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d={statusSvgPath[currentCloudStatus] || statusSvgPath.default}
              />
            </svg>
            <div
              className={classNames(
                "status-page-component-status",
                `status-page-component-status-${currentCloudStatus}`
              )}
            >
              {statusTransform[currentCloudStatus] || statusTransform.unknown}
            </div>
          </a>
        </StyledDiv>
      </React.Fragment>
    );
  }
}
