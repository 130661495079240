export const getLocationData = async () => {
  let location_info = {};
  try {
    location_info = await (
      await fetch(process.env.REACT_APP_URL_LOCATION_INFO)
    ).json();
  } catch (e) {
    // ignore
  }
  return location_info;
};
