import React from "react";
import styled from "styled-components";
import { dangerText } from "../color";

const Title = styled.h2`
  color: ${({ isDanger }) => isDanger && dangerText};
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default class TitleComponent extends React.Component {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Title className="mdc-typography--headline6" {...rest}>
        {children}
      </Title>
    );
  }
}
