import React from "react";
import styled from "styled-components";
import StatusPageComponentStatus from "../components/StatusPageComponentStatus";

const FooterStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  text-align: center;
  margin-top: 15px;
  color: #5d5d5d;
  font-size: 0.875rem;

  a {
    color: #5d5d5d;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1em;
    font-size: 1rem;

    p {
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
`;

const Footer = () => (
  <FooterStyle>
    <p>Nabu Casa, Inc.</p>
    <a
      href="https://www.nabucasa.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Website
    </a>
    <a
      href="https://www.nabucasa.com/support/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Support
    </a>
    <StatusPageComponentStatus />
  </FooterStyle>
);

export default Footer;
