import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Button from "../components/Button";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import TextField from "../components/TextField";
import Footer from "../components/Footer";
import FlashMessage from "../components/FlashMessage";
import { forgotPassword } from "./amplify";
import ConfirmForgotPasswordForm from "./ConfirmForgotPasswordForm";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

class ResetPassForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      error: undefined,
      confirm: false,
      email: props.email || "",
      password: "",
      password2: "",
    };
  }

  componentDidMount() {
    this.handleUrl();
  }

  handleUrl() {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.has("email")) {
      this.setState({ email: searchParams.get("email") });
    }
    if (document.location.pathname === "/forgot-password/confirm") {
      this.setState({ confirm: true });
    }
  }

  onChange = (ev) => {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  submit = async (ev) => {
    ev.preventDefault();
    const { email } = this.state;

    if (!email) {
      return;
    }

    this.setState({
      submitting: true,
    });

    try {
      await forgotPassword(email);
      this.setState({
        submitting: false,
        confirm: true,
      });
    } catch (e) {
      this.setState({
        submitting: false,
        error:
          e.code === "UserNotFoundException" ? "Username not found" : e.message,
      });
      console.error(e);
    }
  };

  clearFlashMessage = () => this.setState({ message: undefined });

  render() {
    const { email, confirm } = this.state;

    if (confirm && email) {
      return <ConfirmForgotPasswordForm email={email} />;
    }

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    return (
      <Page>
        <Header />
        {this.state.error && (
          <FlashMessage
            message={this.state.error}
            isDanger={true}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Forgot your password?
            "
            actionIcons={<Button>Reset my password</Button>}
          >
            <p>
              Enter your email below and we will send a message to reset your
              password.
            </p>
            <StyledTextField
              className="code-input"
              id="email"
              hint="Email"
              onChange={this.onChange}
              value={email}
            />
          </Card>
        </form>
        <Footer />
      </Page>
    );
  }
}

export default ResetPassForm;
