// https://material.io/develop/web/components/cards/
import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import "@material/card/mdc-card.scss";
import CardBody from "./CardBody";
import Title from "./Title";

const StyledCard = styled.div`
  position: relative;
  background-color: ${({ isDanger }) =>
    isDanger && "rgba(202, 21, 21, 0.12) !important"};
`;

const StyledActionButton = styled.div`
  width: ${({ stacked }) => stacked && "100%"};
  flex-direction: ${({ stacked }) => stacked && "column"};
`;

const StyledCardBody = styled(CardBody)`
  margin: -16px 0 -8px;
  .error {
    color: #ca1515;
  }
  .warning {
    color: #ffa600;
  }
  > :first-child {
    margin-top: 14px;
  }
`;

const Primary = styled.div`
  margin: 1rem;
`;

const Subtitle = styled.h3`
  margin: 0;
`;

export default class Card extends React.Component {
  render() {
    const {
      title,
      subtitle,
      actionButtons,
      actionIcons,
      actionStacked,
      className,
      footer,
      documentationLink,
      ...rest
    } = this.props;

    return (
      <StyledCard className={classNames(className, "mdc-card")} {...rest}>
        {title && (
          <Primary>
            <Title>
              {title}
              {documentationLink ? (
                <a
                  className="card-documentation-icon"
                  href={documentationLink}
                  target="_blank"
                  title="Documentation"
                  rel="noreferrer"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </a>
              ) : (
                ""
              )}
            </Title>
            {subtitle && (
              <Subtitle className="mdc-typography--subtitle2">
                {subtitle}
              </Subtitle>
            )}
          </Primary>
        )}
        <StyledCardBody className="styled-card-body">
          {this.props.children}
        </StyledCardBody>
        {(actionButtons || actionIcons) && (
          <div className="mdc-card__actions">
            {actionButtons && (
              <StyledActionButton
                className="mdc-card__action-buttons"
                stacked={actionStacked}
              >
                {actionButtons}
              </StyledActionButton>
            )}
            {actionIcons && (
              <div className="mdc-card__action-icons">{actionIcons}</div>
            )}
          </div>
        )}
        {footer}
      </StyledCard>
    );
  }
}
