import React from "react";
import styled from "styled-components";
import { dangerText } from "../color";
import Button from "../components/Button";
import Card from "../components/Card";
import FlashMessage from "../components/FlashMessage";
import Header from "../components/Header";
import Checkbox from "../components/Checkbox";
import Spinner from "../components/Spinner";
import Footer from "../components/Footer";
import TextField from "../components/TextField";
import ActivateForm from "./ActivateForm";
import { signUp } from "./amplify";
import { getLocationData } from "./location_data";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;
const StyledSuggestionButton = styled(Button)`
  background: none;
  color: ${dangerText} !important;
  border: none;
  padding: 0;
  font: inherit;
  text-align: left;
  cursor: pointer;
  text-transform: initial;
`;

const gmailVariants = [
  "gmai.com",
  "gmail.con",
  "gmail.vom",
  "gmail.vomc",
  "gmailcom",
  "gmil.com",
];

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activate: false,
      submitting: false,
      error: undefined,
      suggestion: undefined,
      termsAccepted: false,
      username: "",
      password: "",
      password2: "",
    };
  }

  componentDidMount() {
    this.handleUrl();
  }

  handleUrl() {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.has("email")) {
      this.setState({ username: searchParams.get("email") });
    }
    if (document.location.pathname === "/signup/confirm") {
      this.setState({ activate: true });
    }
  }

  onChange = (ev) => {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  termsAcceptedChanged = async (ev) => {
    this.setState({ termsAccepted: ev.target.checked });
  };

  onBlur = (ev) => {
    if (ev.target.id === "username") {
      const inputValue = ev.target.value;
      const [username, provider] = inputValue.split("@");
      if (gmailVariants.includes(provider)) {
        this.setState({ suggestion: `${username.toLowerCase()}@gmail.com` });
      } else if (inputValue !== inputValue.toLowerCase()) {
        this.setState({ suggestion: inputValue.toLowerCase() });
      } else if (this.state.suggestion) {
        this.setState({ suggestion: undefined });
      }
    }
  };

  submit = async (ev) => {
    ev.preventDefault();

    const { username, password, password2, termsAccepted } = this.state;

    if (!username || !password) {
      return;
    }

    if (password !== password2) {
      this.setState({
        error: "Passwords don't match",
      });
      return;
    }

    if (!termsAccepted) {
      this.setState({
        error: "You need to accept the terms before creating an account.",
      });
      return;
    }

    this.setState({
      submitting: true,
    });

    const location_info = await getLocationData();

    try {
      const user = await signUp(username, password, {
        NC_COUNTRY_CODE: location_info.country,
        NC_REGION_CODE: location_info.region_code,
        NC_ZIP_CODE: location_info.postal_code,
      });
      this.setState({
        user,
        submitting: false,
        activate: true,
      });
    } catch (e) {
      let error = e.message;
      if (e.code === "UserLambdaValidationException") {
        error = error.split("||")[1] || error;
      }
      this.setState({
        submitting: false,
        error,
      });
      console.error(e);
    }
  };

  useSuggestion = () =>
    this.setState({ username: this.state.suggestion, suggestion: undefined });

  clearFlashMessage = () => this.setState({ error: undefined });

  render() {
    const { username, password, password2 } = this.state;

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    if (this.state.activate && username) {
      return (
        <ActivateForm username={username} password={this.state.password} />
      );
    }

    return (
      <Page>
        <Header />
        {this.state.error && (
          <FlashMessage
            message={this.state.error}
            isDanger={true}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Create an account"
            actionIcons={<Button>Create account</Button>}
          >
            <StyledTextField
              className="username-input"
              type="email"
              id="username"
              hint="Email"
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={username}
              autoComplete="username"
            />
            {this.state.suggestion ? (
              <StyledSuggestionButton onClick={this.useSuggestion}>
                Did you mean <code>{this.state.suggestion}</code>?
              </StyledSuggestionButton>
            ) : (
              ""
            )}
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password"
              hint="Password"
              helperText="Minimum of 8 characters"
              value={password}
              autoComplete="new-password"
            />
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password2"
              hint="Repeat password"
              value={password2}
              autoComplete="new-password"
            />
            <Checkbox
              label={
                <React.Fragment>
                  <div className="pre-terms">
                    By creating an account, I accept the{" "}
                    <a
                      href="https://nabucasa.com/tos/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Services
                    </a>
                    . I understand that Home Assistant Cloud connects to my Home
                    Assistant installation and does <strong>NOT</strong> replace
                    it.
                  </div>
                </React.Fragment>
              }
              onChange={this.termsAcceptedChanged}
              checked={this.state.termsAccepted}
            />
          </Card>
        </form>
        <Footer />
      </Page>
    );
  }
}

export default SignupForm;
