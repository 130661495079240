import Amplify, { Auth } from "aws-amplify";
import storage from "./storage";
import { clientId, userPoolId } from "./const";

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",

    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: "XX-XXXX-X",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: clientId,

    // Prevent amplify from storing the tokens to localStorage
    storage,
  },
});

export const signUp = async (username, password, clientMetadata) => {
  const { user } = await Auth.signUp({
    username: username.toLowerCase(),
    password,
    clientMetadata,
  });
  return user;
};

export const confirmSignUp = (username, code, clientMetadata) =>
  Auth.confirmSignUp(username, code, { clientMetadata });

export const signIn = async (username, password) => {
  try {
    return await Auth.signIn(username, password);
  } catch (e) {
    const lowerCaseUsername = username.toLowerCase();
    if (e.code === "UserNotFoundException" && username !== lowerCaseUsername) {
      return Auth.signIn(lowerCaseUsername, password);
    }
    throw e;
  }
};

export const resendConfirmationCode = (username) => Auth.resendSignUp(username);

export const signOut = async (global) => Auth.signOut({ global });

export const forgotPassword = async (username) => {
  try {
    return await Auth.forgotPassword(username);
  } catch (e) {
    const lowerCaseUsername = username.toLowerCase();
    if (e.code === "UserNotFoundException" && username !== lowerCaseUsername) {
      return Auth.forgotPassword(lowerCaseUsername);
    }
    throw e;
  }
};

export const completeNewPassword = (user, password) =>
  Auth.completeNewPassword(user, password);

export const forgotPasswordSubmit = async (username, code, password) => {
  try {
    return await Auth.forgotPasswordSubmit(username, code, password);
  } catch (e) {
    const lowerCaseUsername = username.toLowerCase();
    if (e.code === "UserNotFoundException" && username !== lowerCaseUsername) {
      return Auth.forgotPasswordSubmit(lowerCaseUsername, code, password);
    }
    throw e;
  }
};
