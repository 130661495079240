// https://material.io/develop/web/components/buttons/

import React from "react";
import "@material/button/mdc-button.scss";
import classNames from "classnames";
import styled from "styled-components";
import { dangerText } from "../color";

const StyledButton = styled.button`
  --mdc-theme-primary: ${({ isDanger, disabled }) =>
    isDanger && !disabled && `${dangerText} !important`};
`;

const StyledA = styled.a`
  --mdc-theme-primary: ${({ isDanger, disabled }) =>
    isDanger && !disabled && `${dangerText} !important`};
`;

export default class Button extends React.Component {
  render() {
    const { children, className, label, ...rest } = this.props;
    return (
      <StyledButton className={classNames(className, "mdc-button")} {...rest}>
        <span className="mdc-button__ripple"></span>
        <span className="mdc-button__touch"></span>
        <span className="mdc-button__focus-ring"></span>
        <span className="mdc-button__label">{label}</span>
        {children}
      </StyledButton>
    );
  }
}

export class AButton extends React.Component {
  render() {
    const { children, className, label, ...rest } = this.props;
    return (
      <StyledA className={classNames(className, "mdc-button")} {...rest}>
        <span className="mdc-button__ripple"></span>
        <span className="mdc-button__touch"></span>
        <span className="mdc-button__focus-ring"></span>
        <span className="mdc-button__label">{label}</span>
        {children}
      </StyledA>
    );
  }
}
