import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Button from "../components/Button";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import TextField from "../components/TextField";
import Footer from "../components/Footer";
import FlashMessage from "../components/FlashMessage";
import { confirmSignUp, signIn, resendConfirmationCode } from "./amplify";
import { getLocationData } from "./location_data";
import LinkButton from "../components/LinkButton";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const LinkButtonMargin = styled(LinkButton)`
  margin: 16px;
`;

class ActivateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      message: undefined,
      danger: false,
      done: false,
      code: "",
    };
  }

  componentDidMount() {
    this.handleUrl();
  }

  handleUrl() {
    const searchParams = new URLSearchParams(document.location.search);

    if (searchParams.has("code")) {
      this.setState({ code: searchParams.get("code") }, this.submit);
    }
  }

  onChange = (ev) => {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  submit = async (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    const { username, password } = this.props;
    const { code } = this.state;

    if (!code || !username) {
      return;
    }

    this.setState({
      submitting: true,
    });

    const location_info = await getLocationData();

    try {
      await confirmSignUp(username, code, {
        NC_COUNTRY_CODE: location_info.country,
        NC_REGION_CODE: location_info.region_code,
        NC_ZIP_CODE: location_info.postal_code,
      });
      if (username && password) {
        await signIn(username, password);
        window.history.replaceState({}, "", "/");
      } else {
        this.setState({
          message: "Your email was successfully verified!",
          danger: false,
          done: true,
          submitting: false,
        });
      }
    } catch (e) {
      this.setState({
        submitting: false,
        danger: true,
        message: e.message,
      });
      console.error(e);
    }
  };

  resendCode = async (ev) => {
    ev.preventDefault();
    this.setState({
      submitting: true,
    });
    try {
      await resendConfirmationCode(this.props.username);
      this.setState({ message: "Email sent", danger: false });
    } catch (e) {
      this.setState({
        message: e.message,
        danger: true,
      });
    } finally {
      this.setState({
        submitting: false,
      });
    }
  };

  goToLogin = () => {
    document.location = "/";
  };

  clearFlashMessage = () => this.setState({ message: undefined });

  render() {
    const { code } = this.state;

    if (!this.props.username) {
      return null;
    }

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    if (this.state.done)
      return (
        <Page>
          <Header />
          <Card
            title="Email confirmed"
            actionButtons={<Button onClick={this.goToLogin}>Login</Button>}
          >
            <p>
              Your email address was successfully confirmed. You can now login.
            </p>
          </Card>
        </Page>
      );

    return (
      <Page>
        <Header />
        {this.state.message && (
          <FlashMessage
            message={this.state.message}
            isDanger={this.state.danger}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Confirm email"
            actionIcons={<Button>Confirm email</Button>}
          >
            <p>
              We sent an email to {this.props.username} with an activation code,
              please enter that code below to verify your email address.
            </p>
            <StyledTextField
              className="code-input"
              id="code"
              hint="Code"
              onChange={this.onChange}
              value={code}
            />
          </Card>
        </form>
        <LinkButtonMargin onClick={this.resendCode}>
          Resend confirmation email
        </LinkButtonMargin>
        <Footer />
      </Page>
    );
  }
}

export default ActivateForm;
