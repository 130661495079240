import React from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Button from "../components/Button";
import Header from "../components/Header";
import Spinner from "../components/Spinner";
import TextField from "../components/TextField";
import FlashMessage from "../components/FlashMessage";
import { completeNewPassword } from "./amplify";

const Page = styled.div`
  max-width: 450px;
  margin: 30px auto 16px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

class CompleteNewPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      error: undefined,
      user: props.user,
      password: "",
      password2: "",
    };
  }

  onChange = (ev) => {
    this.setState({
      [ev.target.id]: ev.target.value,
    });
  };

  submit = async (ev) => {
    ev.preventDefault();

    const { user, password, password2 } = this.state;

    if (!user || !password) {
      return;
    }

    if (password !== password2) {
      this.setState({
        error: "Passwords don't match",
      });
      return;
    }

    this.setState({
      submitting: true,
      error: undefined,
    });

    try {
      await completeNewPassword(user, password);
      document.location = "/";
    } catch (e) {
      console.log(e);
      this.setState({
        error: e.message,
        submitting: false,
      });
    }
  };

  clearFlashMessage = () => this.setState({ message: undefined });

  render() {
    const { password, password2 } = this.state;

    if (this.state.submitting)
      return (
        <Page>
          <Header />
          <Spinner />
        </Page>
      );

    return (
      <Page>
        <Header />
        {this.state.error && (
          <FlashMessage
            message={this.state.error}
            isDanger={true}
            onClose={this.clearFlashMessage}
          />
        )}
        <form onSubmit={this.submit}>
          <Card
            title="Change your password
            "
            actionIcons={<Button>Change my password</Button>}
          >
            <p>Enter your new password below.</p>
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password"
              hint="Password"
              helperText="Minimum of 8 characters"
              value={password}
              autoComplete="new-password"
            />
            <StyledTextField
              className="password-input"
              type="password"
              onChange={this.onChange}
              id="password2"
              hint="Repeat password"
              value={password2}
              autoComplete="new-password"
            />
          </Card>
        </form>
      </Page>
    );
  }
}

export default CompleteNewPasswordForm;
