// Return if we should run in debug mode
export const debug = process.env.REACT_APP_DEBUG === "true";
export const paypalEnabled = process.env.REACT_APP_PAYPAL_ENABLED === "true";
export const baseUrl = `${location.protocol}//${location.host}`;
export const threeDSecureEnabled =
  process.env.REACT_APP_3D_SECURE_ENABLED === "true";

export const currencyPerCountry = {
  GB: "gbp",
  CA: "cad",
  BE: "eur",
  BG: "eur",
  CZ: "eur",
  DK: "eur",
  DE: "eur",
  EE: "eur",
  IE: "eur",
  EL: "eur",
  GR: "eur",
  ES: "eur",
  FR: "eur",
  HR: "eur",
  IT: "eur",
  CY: "eur",
  LV: "eur",
  LT: "eur",
  LU: "eur",
  HU: "eur",
  MT: "eur",
  NL: "eur",
  AT: "eur",
  PL: "eur",
  PT: "eur",
  RO: "eur",
  SI: "eur",
  SK: "eur",
  FI: "eur",
  SE: "eur",
};
