// https://material.io/develop/web/components/input-controls/form-fields/

import React from "react";
import "@material/form-field/mdc-form-field.scss";
import "@material/radio/mdc-radio.scss";

export default class FormField extends React.Component {
  render() {
    const { children, id, label } = this.props;
    return (
      <div className="mdc-form-field">
        {children}
        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
}
