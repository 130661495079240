import React from "react";
import styled from "styled-components";

const HeaderImg = styled.img`
  height: 63.8px;
  margin: 0 20px 10px;
  max-width: calc(100% - 40px);
`;

const Header = () => (
  <a href="https://www.nabucasa.com" target="_blank" rel="noopener noreferrer">
    <HeaderImg
      src={"/static/images/logo-horizontal.svg"}
      alt="Nabu Casa logo"
    />
  </a>
);

export default Header;
