import "./util/sentry";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withAuth } from "./auth";

class AppMount extends Component {
  constructor(props) {
    super(props);
    this.state = { ProfileComponent: null };
  }

  componentDidMount() {
    import(/* webpackChunkName: "app" */ "./App").then((mdl) =>
      this.setState({ ProfileComponent: mdl.default })
    );
  }

  render() {
    const { ProfileComponent } = this.state;

    if (ProfileComponent === null) return null;

    return <ProfileComponent {...this.props} />;
  }
}

const AppMountAuth = withAuth(AppMount);

ReactDOM.render(<AppMountAuth />, document.getElementById("root"));
