// https://material.io/develop/web/components/input-controls/text-field/

import React from "react";
import styled from "styled-components";
import { MDCTextField } from "@material/textfield";
import "@material/textfield/mdc-text-field.scss";
import classNames from "classnames";
import { debug } from "../const";

const StyledLabel = styled.label`
  margin: 8px 0px;
`;

export default class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.root = React.createRef();
  }

  componentDidMount() {
    const { disabled } = this.props;
    this.textfield = new MDCTextField(this.root.current);
    this.textfield.foundation.setDisabled(disabled);
  }

  componentWillUnmount() {
    this.textfield.destroy();
  }

  render() {
    const { id, hint, fullWidth, className, helperText, ...rest } = this.props;
    const inputProps = {
      id,
      type: "text",
      className: "mdc-text-field__input",
      ...rest,
    };

    if (!id && debug) {
      console.warn("Usability warning. Textfield without ID", this.props);
    }

    let helperId;

    if (helperText) {
      helperId = `${id}-helper-text`;
      inputProps["aria-controls"] = helperId;
      inputProps["aria-describedby"] = helperId;
    }

    return (
      <React.Fragment>
        <StyledLabel
          className={classNames(
            className,
            "mdc-text-field mdc-text-field--filled",
            {
              "mdc-text-field--fullwidth": fullWidth,
            }
          )}
          ref={this.root}
        >
          <span className="mdc-text-field__ripple"></span>
          <span className="mdc-floating-label" htmlFor={id}>
            {hint}
          </span>
          <input {...inputProps}></input>
          <span className="mdc-line-ripple"></span>
        </StyledLabel>
        {helperText && (
          <p
            id={helperId}
            className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
            aria-hidden="true"
          >
            {helperText}
          </p>
        )}
      </React.Fragment>
    );
  }
}
