import Raven from "raven-js";

import { debug } from "../const";

if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
  // https://docs.sentry.io/clients/javascript/config/
  Raven.config(process.env.REACT_APP_SENTRY_DSN, {
    release: process.env.GIT_COMMIT,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
  Raven.install();
}

export function setSentryUserContext(authData, subscriptionInfo) {
  Raven.setUserContext({
    username: authData.username,
    ...(subscriptionInfo || {}),
  });
}

// action is a string representing the action of the user
export async function captureException(action, err) {
  const response = err.response;
  if (debug) console.error("Error doing", action, err, response);
  if (process.env.REACT_APP_ENVIRONMENT === "dev") return;

  const extra = {};

  if (response) {
    extra.response = {
      status: response.status,
      url: response.url,
      headers: response.headers,
    };
  }

  if (response && !response.bodyUsed) {
    extra.response.data = await response.text();
  }

  Raven.captureException(err, {
    tags: { action },
    extra,
  });
}

export function captureMessage(message, action, data) {
  if (debug) console.error("Error doing", action, message, data);
  if (process.env.REACT_APP_ENVIRONMENT === "dev") return;
  Raven.captureMessage(`${message} (${action})`, {
    tags: { action },
    extra: data,
  });
}
