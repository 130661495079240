// https://material.io/develop/web/components/input-controls/checkboxes/

import React from "react";
import "@material/checkbox/mdc-checkbox.scss";

import FormField from "./FormField";

export default class Checkbox extends React.Component {
  render() {
    const { id, label, ...rest } = this.props;
    return (
      <FormField id={id} label={label}>
        <div className="mdc-checkbox">
          <input
            type="checkbox"
            className="mdc-checkbox__native-control"
            id={id}
            {...rest}
          />
          <div className="mdc-checkbox__background">
            <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
              <path
                className="mdc-checkbox__checkmark-path"
                fill="none"
                d="M1.73,12.91 8.1,19.28 22.79,4.59"
              />
            </svg>
            <div className="mdc-checkbox__mixedmark" />
          </div>
          <div className="mdc-checkbox__ripple"></div>
        </div>
      </FormField>
    );
  }
}
