/**
 * CardBody
 * Text body to be used inside card.
 */
import React from "react";
import styled from "styled-components";
import classNames from "classnames";

const Body = styled.div`
  padding: 0 1rem 8px;
  color: #232f34;
`;

export default class CardBody extends React.Component {
  render() {
    const { className } = this.props;
    return (
      <Body className={classNames("mdc-typography--body2", className)}>
        {this.props.children}
      </Body>
    );
  }
}
