import * as React from "react";
import styled from "styled-components";

const FlashBody = styled.div`
  padding: 0.5rem 2rem 0.5rem 1rem;
  background-color: ${({ isDanger }) =>
    isDanger ? "rgb(202, 21, 21)" : "#24a7e0"};
  background-color: ${({ isDanger }) =>
    isDanger ? "rgb(202, 21, 21)" : "var(--mdc-theme-primary)"};
  position: relative;
  color: white;
  font-weight: bold;
  border-radius: 2px;

  a {
    color: white;
  }
`;

const FlashClose = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background: 0;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
`;

const FlashMessage = ({ message, onClose, isDanger }) => (
  <FlashBody isDanger={isDanger}>
    <FlashClose onClick={() => onClose()}>X</FlashClose>
    {message}
  </FlashBody>
);

export default FlashMessage;
